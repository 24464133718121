// common environment for all stages dev host_url defaults to staging to allow access of other apps in dev mode
const common = {};

const env = {
  dev: {
    API_BASE_URL: 'https://dtstagingapi.azurewebsites.net/api/v1',
    ROUTER_BASE_NAME: '/',
    CLIENT_URL: 'http://localhost:3000',
  },
  test: {
    API_BASE_URL: 'https://dtstagingapi.azurewebsites.net/api/v1',
    ROUTER_BASE_NAME: '/',
    CLIENT_URL: 'https://staging.client.mydonorstrust.org',
  },
  staging: {
    API_BASE_URL: 'https://dtstagingapi.azurewebsites.net/api/v1',
    ROUTER_BASE_NAME: '/',
    CLIENT_URL: 'https://staging.client.mydonorstrust.org',
  },
  production: {
    API_BASE_URL: 'https://donorstrustapi.azurewebsites.net/api/v1',
    ROUTER_BASE_NAME: '/',
    CLIENT_URL: 'https://client.mydonorstrust.org',
  },
};

export default {
  ...common,
  ...env[process.env.REACT_APP_STAGE],
};
