import React, { useContext } from 'react';
// import { Redirect } from 'react-router-dom';
import { AuthenticationContext } from 'auth/AuthProvider';

export default () => {
  const { removeToken } = useContext(AuthenticationContext);

  // if (!accessToken) return <Redirect to="/" />;

  return (
    <button
      className="navbar__logout-btn"
      type="button"
      onClick={() => {
        removeToken();
      }}
    >
      Logout
    </button>
  );
};
