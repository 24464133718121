import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { AuthenticationContext } from 'auth/AuthProvider';
import Logout from 'components/Logout';
import DonorsTrustLogo from 'assets/images/DON_logo.png';

const CaretSVG = ({ style }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="10px"
      height="10px"
      viewBox="0 0 292.362 292.362"
      style={{ ...style, enableBackground: 'new 0 0 292.362 292.362' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
		C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
		s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

const NavItem = ({ path, title }) => {
  return (
    <div className="d-flex flex-column align-items-lg-center position-relative">
      <NavLink exact to={path} title={title}>
        {title}
      </NavLink>
      <CaretSVG style={{ position: 'absolute', bottom: '-7px' }} />
    </div>
  );
};

export default () => {
  const { isLoggedIn } = useContext(AuthenticationContext);

  return (
    <div className="navbar-container">
      <Navbar sticky="top" expand="lg" role="navigation">
        <NavLink to="/" title="Donors Trust">
          <img src={DonorsTrustLogo} className="d-inline-block" alt="DonorsTrust Logo" />
        </NavLink>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end my-3">
          {isLoggedIn && (
            <Nav className="mx-auto">
              <NavItem path="/" title="Dashboard" />
              <NavItem path="/users/authorize" title="Authorize New User" />
            </Nav>
          )}
          {isLoggedIn ? (
            <Logout />
          ) : (
            <Nav className="ml-auto">
              <NavItem path="/login" title="Login" />
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
