import React, { useContext } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AlertContext } from 'Providers/Alert';
import { ApiContext } from 'components/ApiProvider';
import { formatErrors } from 'util/formatErrors';

const resolver = yupResolver(
  yup.object().shape({
    username: yup.string().required('Username required'),
    contactId: yup.number().required('Contact ID required'),
  })
);

const AuthorizeUserForm = ({ contactId, onSaveSuccess }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver,
    defaultValues: {
      contactId,
    },
  });
  const { addAlert } = useContext(AlertContext);
  const { authorizeUser } = useContext(ApiContext);

  const submit = async (values) => {
    const { username, contactId: cid } = values;
    try {
      await authorizeUser(username, cid);
      addAlert(
        'success',
        'User authorized. They will receive an email with instructions on how to login and change their password.'
      );
      reset();
      onSaveSuccess(values);
    } catch (e) {
      const errMsg = formatErrors(e.response.data);

      addAlert('error', errMsg);
    }
  };

  return (
    <Form className="form my-4" onSubmit={handleSubmit((values) => submit(values))}>
      <Form.Row>
        <Form.Group as={Col} controlId="username">
          <Form.Label>Username:</Form.Label>
          <Form.Control type="text" {...register('username')} isInvalid={errors.username} />
          <Form.Control.Feedback type="invalid">{errors.username?.message}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row className="mt-4">
        <Form.Group as={Col}>
          <button className="button button--dark-blue px-3 py-2" type="submit">
            Authorize User
          </button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default AuthorizeUserForm;
