import React, { createContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getAuthToken, setAuthToken, getUser, removeAuthToken } from 'util/auth';

export const AuthenticationContext = createContext();

const AuthProviderComponent = ({ children }) => {
  const [accessToken, setAccessToken] = useState(getAuthToken());
  const [user, setUser] = useState(getUser(accessToken));

  const saveToken = (userToken) => {
    setAuthToken(userToken);
    setAccessToken(userToken);
  };

  const removeToken = () => {
    removeAuthToken();
    setAccessToken(null);
  };

  useEffect(() => {
    setAccessToken(getAuthToken());
    setUser(getUser(accessToken));
  }, [accessToken]);

  // const getUserById = async () => {
  //   const fetchedUser = await userById(userId);
  //   setUser(fetchedUser);
  // };
  // useEffect(() => {
  //   if (accessToken) {
  //     setUserId(getUser(accessToken));
  //   }
  // }, [accessToken]);

  // useEffect(() => {
  //   if (userId && !user) {
  //     getUserById(userId);
  //   }
  // }, [user, userId]);

  return (
    <AuthenticationContext.Provider
      value={{
        setToken: saveToken,
        removeToken,
        accessToken,
        user,
        isLoggedIn: !!accessToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const AuthProvider = withRouter(AuthProviderComponent);
