import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ResetPasswordModal } from 'components/Main/Login/ResetPasswordModal';
import { AuthenticationContext } from 'auth/AuthProvider';
import env from 'environment';
import { useLogin } from './hooks';
import { resolver } from './constants';

export default () => {
  const [showModal, setShow] = useState(false);
  const { isLoggedIn } = useContext(AuthenticationContext);
  const { login } = useLogin();

  const toggleModal = () => setShow((prev) => !prev);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver });

  if (isLoggedIn) return <Redirect to="/" />;

  return (
    <div className="login-wrapper">
      <div className="container my-3">
        <h3 className="login-header mb-3 text-center">DonorsTrust Admin Login</h3>
        <div className="row">
          <div className="col col-sm-8 offset-sm-2 col-md-6 offset-md-3">
            <Form className="form my-4" onSubmit={handleSubmit((values) => login(values))}>
              <Form.Row className="align-items-center">
                <Form.Label className="pr-3 col-4" align="right">
                  Username:
                </Form.Label>
                <Form.Control
                  className="col"
                  {...register('username')}
                  isInvalid={errors.username}
                  type="text"
                />
              </Form.Row>
              <Form.Row className="mt-3 align-items-center">
                <Form.Label className="pr-3 col-4" align="right">
                  Password:
                </Form.Label>
                <Form.Control
                  className="col"
                  type="password"
                  {...register('password')}
                  isInvalid={errors.password}
                />
              </Form.Row>
              <Form.Row>
                <div className="offset-4 w-100">
                  <button className="button button--dark-blue mt-3 px-4 py-2 w-100" type="submit">
                    Login
                  </button>
                  <a
                    className="d-block text-black w-100 text-center mt-3"
                    href={`${env.CLIENT_URL}/password/reset`}
                  >
                    <u>Reset Password</u>
                  </a>
                </div>
              </Form.Row>
            </Form>
          </div>
        </div>
      </div>
      <ResetPasswordModal show={showModal} onHide={toggleModal} />
    </div>
  );
};
