import React from 'react';
import { css } from '@emotion/react';
import GridLoader from 'react-spinners/GridLoader';

const override = css`
  display: block;
  margin: 0 auto;
`;

const LoadingSpinner = () => {
  return (
    <div>
      <GridLoader color="#cfab7a" size={15} css={override} />
    </div>
  );
};

export default LoadingSpinner;
