export const formatErrors = (err) => {
  let msg = '';

  if (typeof err === 'object') {
    const errObj = err.errors;

    Object.keys(errObj).forEach((key) => {
      const errorArr = errObj[key];

      msg += errorArr.join(' ');
      msg += '<br\\><br \\>';
    });
  } else if (typeof err === 'string') {
    msg = err;
  }

  return msg;
};
