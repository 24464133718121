import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import AuthorizeUserForm from '../Form';

const AuthorizeUserModal = ({ showModal, contactId, onAdd, onClose }) => {
  const [show, setShow] = useState(showModal);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Authorize User {contactId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AuthorizeUserForm contactId={contactId} onSaveSuccess={onAdd} />
      </Modal.Body>
    </Modal>
  );
};

export default AuthorizeUserModal;
