import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticationContext } from 'auth/AuthProvider';

const SecuredRoute = (props) => {
  const { isLoggedIn } = useContext(AuthenticationContext);

  if (!isLoggedIn) return <Redirect to="/login" />;

  return <Route {...props} />;
};

export default SecuredRoute;
