import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from 'components/ApiProvider';
import { AlertContext } from 'Providers/Alert';

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const { getUsers, unlockUserAccount } = useContext(ApiContext);
  const { addAlert } = useContext(AlertContext);

  // TODO: Endpoint currently gets ALL users
  //       Will need to get paginated results
  useEffect(() => {
    async function getAllUsers() {
      const data = await getUsers();
      setUsers(data);
    }

    getAllUsers();
  }, []);

  const unlockAccount = async (userId) => {
    try {
      await unlockUserAccount(userId);
      addAlert('success', 'Account successfully unlocked.');
    } catch {
      addAlert('error', 'There was an error unlocking account.');
    }
  };

  const filteredUsers = () => {
    const data = users.filter(
      (u) =>
        (u.firstName && u.firstName.toLowerCase().includes(query)) ||
        (u.lastName && u.lastName.toLowerCase().includes(query)) ||
        (u.email && u.email.toLowerCase().includes(query))
    );
    return data;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col col-md-10 offset-md-1">
          <input
            value={query}
            className="form-control mb-4"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
            placeholder="Type to filter users"
          />
          <table className="table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>UserName</th>
                <th className="text-center">Unlock account</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers().map((u) => {
                return (
                  <tr key={u.userId}>
                    <td>{u.firstName}</td>
                    <td>{u.lastName}</td>
                    <td>{u.email}</td>
                    <td>{u.username}</td>
                    <td align="center">
                      <button
                        className="button button--link"
                        type="button"
                        onClick={() => unlockAccount(u.userId)}
                      >
                        Unlock
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
