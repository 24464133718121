import axios from 'axios';
import jwtDecode from 'jwt-decode';
import env from 'environment';
import React, { useState, useContext, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthenticationContext } from 'auth/AuthProvider';

export const ApiContext = createContext();

const ApiProviderComponent = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, accessToken, removeToken } = useContext(AuthenticationContext);

  const instance = axios.create({
    baseURL: env.API_BASE_URL,
  });

  instance.interceptors.request.use((req) => {
    if (accessToken) {
      req.headers.Authorization = `Bearer ${accessToken}`;
    }

    return req;
  });

  instance.interceptors.response.use(
    (res) => {
      const { headers } = res;
      const newAuthToken = headers['x-dt-token'];

      if (newAuthToken) {
        setToken(newAuthToken);
      }

      return res;
    },
    async (err) => {
      if (err.response?.status === 401) {
        try {
          removeToken();
          // await instance.post('/Auth/logout');
        } catch (e) {
          console.error(e);
        }
      } else {
        const { headers } = err.response;
        const newAuthToken = headers['x-dt-token'];

        if (newAuthToken) {
          setToken(newAuthToken);
        }
      }

      return Promise.reject(err);
    }
  );

  const request = async (method, url, params, data, headers) => {
    try {
      // don't load indicator on search grantees endpoint
      if (url !== '/grantees') {
        setIsLoading(true);
      }

      const res = await instance.request({
        method,
        url,
        headers,
        params,
        data,
      });

      return res.data;
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    } finally {
      setIsLoading(false);
    }
  };

  const get = async (path, params, headers) => {
    return request('get', path, params, null, headers);
  };

  const post = async (path, data, headers) => {
    return request('post', path, null, data, headers);
  };

  const endpoints = {
    login: async (username, password) => {
      try {
        const data = await post('/auth/login', { username, password });
        const payload = jwtDecode(data.token);
        const role = payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        if (role === 'Admin') {
          return data;
        }

        return Promise.reject(new Error('Unauthorized access'));
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getUsers: async () => {
      try {
        return await get('/users');
      } catch {
        return [];
      }
    },
    unlockUserAccount: async (userId) => {
      return post(`/users/${userId}/unlock-account`);
    },
    getUnauthorizedUsers: async () => {
      try {
        return await get('/users/unauthorized');
      } catch {
        return [];
      }
    },
    authorizeUser: async (username, contactId) => {
      return post('/users/authorize', { username, contactId });
    },
  };

  return <ApiContext.Provider value={{ ...endpoints, isLoading }}>{children}</ApiContext.Provider>;
};

export const ApiProvider = withRouter(ApiProviderComponent);
