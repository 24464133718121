import React from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';

export const ResetPasswordModal = ({ show, onHide }) => {
  return (
    <Modal dialogClassName="reset-password-modal" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h4>Donors trust &gt; Login Page</h4>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          If you forgot your password an email with a password reset link will be sent to you. Click
          on the link in that email and you will be taken to a page where you can then create a new
          password.
        </Alert>
        <Form className="d-flex justify-content-center pt-3">
          <Form.Label className="pr-3">Username:</Form.Label>
          <Form.Control className="w-25" />
        </Form>
        <div className="pt-5 pb-5">
          <Button className="mr-2" title="Send Reset Link">
            Send Reset Link
          </Button>
          <Button variant="secondary" onClick={onHide} title="Cancel">
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
