import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from 'components/ApiProvider';
import AuthorizeUserModal from './Modal';

const AuthorizeUser = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);

  const { getUnauthorizedUsers } = useContext(ApiContext);

  useEffect(() => {
    const fetchUnauthorizedUsers = async () => {
      const u = await getUnauthorizedUsers();
      setUsers(u);
    };
    fetchUnauthorizedUsers();
  }, []);

  const handleAuthorize = (user) => {
    setUsers(users.filter((u) => u.id !== user.contactId));
    setSelectedContactId(null);
    setShowModal(false);
  };

  const selectUser = (contactId) => {
    setSelectedContactId(contactId);
    setShowModal(true);
  };

  return (
    <>
      <AuthorizeUserModal
        showModal={showModal}
        contactId={selectedContactId}
        onAdd={handleAuthorize}
        onClose={() => setShowModal(false)}
      />
      <div className="container">
        <div className="row">
          <div className="col col-md-8 offset-md-2">
            <h1>Authorize a new user</h1>
            <p>
              Select a user below. You will then be prompted to provide a username for them. Once
              provided, an account will be created for the user and an email with instructions on
              how to access the account will be sent to the user
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Contact ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th aria-label="empty column header" />
                </tr>
              </thead>
              <tbody>
                {users.map((u) => {
                  return (
                    <tr key={u.id}>
                      <td>{u.id}</td>
                      <td>{u.firstName}</td>
                      <td>{u.lastName}</td>
                      <td>{u.email1}</td>
                      <td align="center">
                        <button
                          className="button button--link"
                          type="button"
                          onClick={() => selectUser(u.id)}
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizeUser;
