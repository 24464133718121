import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from 'components/NavBar';
import Main from 'components/Main';
import Footer from 'components/Footer';
import { AuthProvider } from 'auth/AuthProvider';
import { ApiProvider, ApiContext } from 'components/ApiProvider';
import { AlertProvider } from 'Providers/Alert';
import LoadingSpinner from 'components/LoadingSpinner';
import env from './environment';

const WrappedApp = () => {
  return (
    <AlertProvider>
      <AuthProvider>
        <ApiProvider>
          <ApiContext.Consumer>
            {(ctx) => {
              if (ctx.isLoading) {
                return (
                  <div className="loader">
                    <LoadingSpinner />
                  </div>
                );
              }

              return null;
            }}
          </ApiContext.Consumer>
          <NavBar />
          <Main />
          <Footer />
        </ApiProvider>
      </AuthProvider>
    </AlertProvider>
  );
};

const App = () => (
  <Router basename={env.ROUTER_BASE_NAME}>
    <Switch>
      <Route path="/" component={WrappedApp} />
    </Switch>
  </Router>
);

export default App;
