import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AlertContext } from 'Providers/Alert';
import AlertComponent from 'components/Alert';
import Login from 'components/Main/Login';
import Dashboard from 'components/Main/Dashboard';
import AuthorizeUser from 'components/Main/User/Authorize';
import PrivateRoute from 'PrivateRoute';

export default () => {
  const { alertType, alertMsg, removeAlert } = useContext(AlertContext);

  return (
    <div className="main-wrapper">
      <div className="main-content py-4 position-relative">
        <AlertComponent type={alertType} msg={alertMsg} removeCallback={removeAlert} />
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/users/authorize" component={AuthorizeUser} />
        </Switch>
      </div>
    </div>
  );
};
