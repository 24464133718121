import React from 'react';
// import { Link } from 'react-router-dom';

export default () => {
  // const currentDate = new Date();
  // const year = currentDate.getFullYear();

  return (
    <div className="footer">
      {/* <span className="mr-2">Copyright {year}, Donorstrust, Inc.</span>
      <Link to="/terms" className="ml-1 mr-1" title="Terms Of Use">
        Terms Of Use
      </Link>
      <span>|</span>
      <Link to="/privacy" className="ml-1 mr-1" title="Privacy Statement">
        Privacy Statement
      </Link> */}
    </div>
  );
};
